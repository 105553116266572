import { template as template_57a81ea2f39e42238ee11164200513b3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_57a81ea2f39e42238ee11164200513b3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
