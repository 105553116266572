import { template as template_39408015cf7645aa891d099a2d3ffa39 } from "@ember/template-compiler";
const WelcomeHeader = template_39408015cf7645aa891d099a2d3ffa39(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
