import { template as template_0c55dc8b046e46409cc602ce1d05a99f } from "@ember/template-compiler";
const FKLabel = template_0c55dc8b046e46409cc602ce1d05a99f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
